.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mapboxgl-popup-close-button {
  display: none;
}

.pop-up .mapboxgl-popup-content {
  font-size:0.8rem;
  padding: 5px 10px;
  background-color:#35536C;
  color:#fff
}
.pop-up.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color:#35536C;
}

@media only screen and (max-width: 500px) {
  .mapboxgl-map {
    height: 300px !important;

}
}






